import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import ClosedLOSurvey from "../components/FormBuilder/ClosedLOSurvey/ClosedLOSurvey";
import "../styles/form.scss";

const ClosedLO = () => (
  <AuthenticationLayout>
    <ClosedLOSurvey />
  </AuthenticationLayout>
);

export default ClosedLO;
