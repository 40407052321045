import Agent from "../../../Interfaces/agent";
import { Deal } from "../../../Interfaces/deal";
import { FieldTypes } from "../../../utils/fieldTypes";

const ClosedMPDefaultFields = (agent: Agent, deal: Deal) => {
  const fields = [
    {
      label: `This survey is broken into two sections. The first will ask about your satisfaction with the agent provided for ${deal.customer.firstname}'s transaction. The second will ask about your satisfaction working with Clever on this transaction.<br/><br/>For all scale questions, please consider 1 the lowest possible rating.`,
      keyName: "intro",
      type: FieldTypes.paragraph,
    },
    // AGENT
    {
      label: `${agent.firstname} ${agent.lastname} of ${agent.brokerage_name}`,
      keyName: "agent_brokerage",
      type: FieldTypes.header,
    },
    {
      label: `How would you rate the experience ${agent.firstname} ${agent.lastname} provided to your customer?`,
      keyName: `how_would_you_rate_the_experience_agent_provided_to_your_customer_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `How would you rate your experience working with ${agent.firstname} ${agent.lastname}?`,
      keyName: `how_would_you_rate_your_experience_working_with_agent_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `How well do you think ${agent.firstname} ${agent.lastname} understood the partnership between your company and Clever?`,
      keyName: `how_well_do_you_think_agent_understood_the_partnership_between_your_company_and_clever_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Would you work with ${agent.firstname} ${agent.lastname} again in the future?`,
      keyName: `would_you_work_with_agent_again_in_the_future_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `How did this agent fall short of your expectations?`,
      keyName: `how_did_this_agent_fall_short_of_your_expectations_`,
      type: FieldTypes.textarea,
      triggers: {
        would_you_work_with_agent_again_in_the_future_: ["1", "2", "3", "4"],
      },
    },
    // END AGENT
    // CLEVER REAL ESTATE
    {
      label: `Clever Real Estate`,
      type: FieldTypes.header,
      keyName: "clever",
    },
    {
      label: `How would you rate Clever's communication throughout this transaction?`,
      keyName: `how_would_you_rate_clever_s_communication_throughout_this_transaction_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: "How much do you agree with the following statement:",
      type: FieldTypes.paragraph,
      keyName: "agree",
    },
    {
      label: `Clever added value to this referral`,
      keyName: `clever_added_value_to_this_referral`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Based on your experience with this referral, how likely are you to send future referrals to Clever?`,
      keyName: `how_likely_are_you_to_send_future_referrals_to_clever_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      required: true,
    },
    {
      label: `Where did we fall short of your expectations?`,
      keyName: `where_did_we_fall_short_of_your_expectations___mp_lo_closed_`,
      type: FieldTypes.textarea,
      triggers: {
        how_likely_are_you_to_send_future_referrals_to_clever_: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ],
      },
    },
  ];

  return fields;
};

const getFields = (deal: Deal, agent: Agent) => {
  const fields = ClosedMPDefaultFields(agent, deal);
  return fields;
};

export { getFields };
